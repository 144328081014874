.presale {
  background-color: #ffdc21;
  border-top: 3px solid black;
  height: 100%;

  .skull-logo {
    width: 260px;
    padding: 0 35px;
  }

  .noctis-logo {
    width: 85%;
    padding: 0 35px;
  }

  .title,
  .title-live,
  .subtitle {
    font-family: "Montserrat Bold";
    line-break: anywhere;
  }

  .disclaimer {
    font-family: "Montserrat Medium";
    font-size: 15px;
  }

  .address-box {
    background-color: #8a01ca;
    border-radius: 20px;
    color: white;
    padding: 10px 20px;
    border: 3px solid black;
    letter-spacing: 1.2px;
    font-size: 18px;
    font-family: "Montserrat Bold";
  }
}

.copy-btn {
  border-radius: 10px;
  font-size: 17px;
  font-family: "Montserrat Bold";
  cursor: pointer;
  color: white;
  border: 3px solid black;
  background-color: #8a01ca;
  padding: 5px 15px;
}

@media only screen and (max-width: 576px) {
  .presale {
    .title {
      font-size: 17px;
    }

    .title-live {
      font-size: 16px;
    }

    .subtitle {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 576px) {
  .presale {
    .title {
      font-size: 25px;
    }

    .title-live {
      font-size: 22px;
    }

    .subtitle {
      font-size: 22px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .presale {
    .title {
      font-size: 30px;
    }

    .title-live {
      font-size: 24px;
    }

    .subtitle {
      font-size: 25px;
    }
  }
}

.neonText {
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #ffdc21, 0 0 10px #ffdc21;
  }
  to {
    text-shadow: 0 0 10px #8a01ca, 0 0 20px #8a01ca, 0 0 30px #8a01ca;
  }
}
