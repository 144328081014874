.eyes-follow-tired {
  //   font-family: Arial, sans-serif;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;

  .eyes-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    // .eyelid {
    //   position: absolute;
    //   width: 220px;
    //   height: 100px;
    //   background-color: white;
    //   z-index: 400;
    //   top: -120px;
    //   animation: blink 4.5s infinite ease-in alternate;
    //   animation-delay: 1s;
    // }
    @keyframes blink {
      0% {
        top: -120px;
      }
      95% {
        top: -120px;
      }
      100% {
        top: -50px;
      }
    }

    .eyes {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      left: -190px;
      top: 75px;
      .eye {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background-color: transparent;
        margin: 0 10px 0 10px;
        .pupil {
          position: absolute;
          background-color: #d72def;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          left: 20px;
          top: 15px;
        }
      }
    }
  }

  .tiredness {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;

    .header {
      font-size: 23px;
      margin-bottom: 10px;
      font-style: italic;
    }

    input {
      transform: scale(1.3);
    }
  }
}
