.tokenomics {
  background-color: #36026b;

  .title {
    font-family: "Montserrat Bold";
    font-size: 40px;
    color: white;
    text-transform: uppercase;
  }
}
