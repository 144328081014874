@font-face {
  font-family: "Montserrat Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

body {
  background-color: #BFD7D3;
}

.d-contents {
  display: contents;
}

.pr-0 {
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.absolute {
  position: absolute;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-start {
  display: flex;
  justify-content: start;
}

.justify-end {
  display: flex;
  justify-content: end;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.grid-center {
  display: grid;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-10  {
  padding-left: 10px;
}

.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.plr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.plr-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.plr-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.plr-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}


.float-left {
  float: left;
}

.float-right {
  float: right;
}