.game {
  position: relative;
  height: 100%;
  background-color: #c4b9ce;
  border-top: 3px solid black;
  border-bottom: 3px solid black;

  .wall {
    position: relative;

    .noctis {
      position: absolute;
      z-index: 100;
    }

    .relative {
      position: relative;
      width: 225px;
      height: 80px;

      .crypto-room-img {
        position: relative;
        background-image: url("../../assets/img/crypto-room.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;

        .meme-logo {
          position: absolute;
          left: 3px;
          top: 5px;
          height: 67px;
          width: 67px;
          border-radius: 35px;
        }

        .meme-signal {
          position: absolute;
          top: 10px;
          left: 82px;

          .meme-symbol {
            color: black;
            font-size: 18px;
            font-family: "Montserrat Bold";
          }

          .meme-mc {
            color: #a511ed;
            font-size: 18px;
            font-family: "Montserrat Bold";
          }
        }
      }
    }

    .door-img {
      height: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .game {
    .wall {
      padding-top: 50px;
      bottom: -3px;

      .noctis {
        left: -110px;
        bottom: -70px;

        .noctis-img {
          height: 385px;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) {
  .game {
    .wall {
      padding-top: 50px;
      bottom: -3px;

      .noctis {
        left: -35px;
        bottom: -70px;

        .noctis-img {
          height: 410px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .game {
    .wall {
      padding-top: 0;
      bottom: -63px;

      .noctis {
        left: -185px;
        bottom: -75px;

        .noctis-img {
          height: 425px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .game {
    .wall {
      padding-top: 0;
      bottom: -63px;

      .noctis {
        left: -190px;
        bottom: -80px;

        .noctis-img {
          height: 450px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .game {
    .wall {
      padding-top: 0;
      bottom: -63px;

      .noctis {
        left: -185px;
        bottom: -80px;

        .noctis-img {
          height: 450px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .game {
    .wall {
      padding-top: 0;
      bottom: -63px;

      .noctis {
        left: -265px;
        bottom: -80px;

        .noctis-img {
          height: 450px;
        }
      }
    }
  }
}
