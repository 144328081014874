.amounts {
  /* UI Properties */
  font-family: "Montserrat Bold";
  font-size: 20px;
  color: #000000;
  text-transform: uppercase;
}

input {
  --c: #f700ff; /* active color */
  --l: 8px; /* line thickness*/
  --h: 50px; /* thumb height */
  --w: 50px; /* thumb width */

  width: 100%;
  height: var(--h); /* needed for Firefox*/
  --_c: color-mix(in srgb, var(--c), #000 var(--p, 0%));
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: auto;
  border-radius: 15px;
  overflow: hidden;
}

input:focus-visible,
input:hover {
  --p: 25%;
}

/* chromium */
input[type="range"]::-webkit-slider-thumb {
  height: var(--h);
  width: var(--w);
  //   background: var(--_c);
  border-image: linear-gradient(90deg, var(--_c) 50%, #ababab 0) 0 1 /
    calc(50% - var(--l) / 2) 100vw/0 100vw;
  -webkit-appearance: none;
  background: url("../../assets/img/skullcoin.svg");
  background-size: 50px 50px;
  appearance: none;
  transition: 0.3s;
}
/* Firefox */
input[type="range"]::-moz-range-thumb {
  height: var(--h);
  width: var(--w);
  background: var(--_c);
  border-image: linear-gradient(90deg, var(--_c) 50%, #ababab 0) 0 1 /
    calc(50% - var(--l) / 2) 100vw/0 100vw;
  -webkit-appearance: none;
  background: url("../../assets/img/skullcoin.svg");
  background-size: 50px 50px;
  appearance: none;
  border-radius: 15px;
  transition: 0.3s;
}
