.navbar {
  background-color: white;
  border-top: 3px solid black;

  .header-logo {
    height: 50px;
  }

  .nav-item {
    padding: 5px 15px;

    .nav-link {
      color: black;
      font-size: 22px;
      font-family: "Montserrat Bold";

      &.btn-buy {
        background-color: #8a01ca;
        border-radius: 20px;
        color: white;
        padding: 6px 20px;
        border: 3px solid black;
        letter-spacing: 1.2px;
        font-size: 20px;
        font-family: "Montserrat Bold";
      }
    }
  }

  .rrss-container {
    border: 3px solid #000000;
    border-radius: 20px;
    height: 48px;
    width: 48px;
    margin: 0 5px;

    .rrss-logo {
      height: 22px;

      &.dexscreen {
        margin-bottom: 0px;
        margin-right: 0px;
        height: 30px;
      }

      &.dextools {
        margin-bottom: 0px;
        margin-right: 0px;
        height: 25px;
      }

      &.telegram {
        margin-bottom: 3px;
        margin-right: 3px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .navbar {
    padding: 10px;
  }
}

@media only screen and (min-width: 576px) {
  .navbar {
    padding: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .navbar {
    padding: 0;
  }
}
