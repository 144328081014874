.about {
  background-color: #36026b;

  .title {
    font-family: "Montserrat Bold";
    font-size: 30px;
    color: white;
    text-transform: uppercase;
  }

  .about-box {
    width: 100%;
    background-color: #6006b6;
    border-radius: 12px;
    padding: 30px 50px 40px;
    box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;

    .title {
      font-size: 22px;
      color: #fefefe;
      font-family: "Montserrat Bold";
      text-align: justify;
    }

    .subtitle {
      font-size: 15px;
      color: #fefefe;
      font-family: "Montserrat Medium";
      text-align: justify;
    }
  }
}
